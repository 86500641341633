import { History } from 'history'
import { Middleware } from 'redux'

import { selectQueryState, selectQueryString } from 'client/store/locationState/reducer'
import { getGalleryContextFromQueryString } from 'shared/url'
import { history as galleryHistory } from 'client/utils/history'
import { stateFromQueryStateUpdate } from 'client/store/locationState/actions'

/**
 * Redux dispatch middleware that updates the current location when
 * (and only when) state is changed. If no `history` exists (i.e. on the
 * server-side), this middleware is skipped.
 * @param rules
 */
export const stateToLocation = (history: History | null): Middleware => ({ getState }) => (next) => (action): any => {
  // Only update the url if the action specifically indicates to do so
  if (!history || !action.meta || !action.meta.updateUrl) {
    return next(action)
  }

  const result = next(action)
  const newState = getState()
  const newQueryState = selectQueryState(newState)
  const queryString = selectQueryString(newQueryState, window.location.search)

  if (queryString !== window.location.search) {
    // passing an empty string to push/replace will remove the path
    history.push(queryString || '?')
  }

  return result
}

export const locationToState = (dispatch: State.GalleryThunkDispatch): void => {
  if (!galleryHistory) {
    return
  }

  let previousQueryString = galleryHistory.location.search

  galleryHistory.listen(({ location, action }) => {
    if (action === 'POP' && location.search !== previousQueryString) {
      const queryState = getGalleryContextFromQueryString(location.search)

      dispatch(stateFromQueryStateUpdate(queryState))
    }

    if (action === 'POP') {
      window.scrollTo(0, 0)
    }

    previousQueryString = location.search
  })
}
