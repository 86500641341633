import { JSX, useCallback } from 'react'
import {
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  Icon,
} from '@vp/swan'
import { useDispatch, useSelector } from 'react-redux'
import { resetQuickViewId } from '~/client/store/debug/actions'
import { useTranslations } from '~/client/hooks/useTranslations'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGalleryBaseURL } from 'client/hooks/useGalleryBaseURL'
import { useGalleryDesignIdFromUrl } from 'client/hooks/useGalleryDesignId'

type SimpleModalProps = {
  ariaLabelId?: string
}

// TODO Remove
const SimpleModal = ({ ariaLabelId = 'simple-modal' }: SimpleModalProps): JSX.Element | null => {
  const localize = useTranslations()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const quickViewId = useSelector((store: State.GlobalState) => store.config.designId)

  const designId = useGalleryDesignIdFromUrl()
  const baseUrl = useGalleryBaseURL()

  console.log('SIMPLE MODAL', designId, quickViewId, baseUrl)

  const handleClose = useCallback((): void => {
    dispatch(resetQuickViewId())
    navigate(-1)
  }, [dispatch, navigate])

  const isOpen = quickViewId || designId

  if (!isOpen) {
    return null
  }

  return (
    <ModalDialog
      isOpen={isOpen}
      onlyRenderWhenOpen
      onRequestDismiss={handleClose}
      id={`${ariaLabelId}-${quickViewId}`}
      aria-label={localize('FilterMenuCloseButtonText')}
    >
      <ModalDialogContent>
        <ModalDialogCloseButton
          accessibleText={localize('FilterMenuCloseButtonText')}
        />
        <ModalDialogBody>
          <div className='simple-modal-content' style={{ padding: '20px', textAlign: 'center' }}>
            <div style={{ marginBottom: '15px' }}>
              <Icon
                id='design-icon'
                name='design'
                style={{ fontSize: '48px', color: '#1a73e8' }}
              />
            </div>
            <h2 style={{ margin: '0 0 10px', color: '#333', fontSize: '24px' }}>
              Let's imagine that it's QuickView
            </h2>
            <p style={{ margin: '0 0 20px', color: '#666', fontSize: '16px' }}>
              Design ID: <strong>{quickViewId || designId}</strong>
            </p>
            <button
              onClick={handleClose}
              style={{
                background: '#1a73e8',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '4px',
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'background 0.3s',
              }}
            >
              Closes
            </button>
          </div>
        </ModalDialogBody>
      </ModalDialogContent>
    </ModalDialog>
  )
}

SimpleModal.displayName = 'SimpleModal'

export { SimpleModal }
