import { useEffect, useState } from 'react'
import { matchGalleryParams } from 'client/utils/matchGalleryParams'
import { useLocation } from 'react-router-dom'

export const useGalleryDesignIdFromUrl = (): string => {
  const location = useLocation()
  const [designId, setDesignId] = useState<string>('')

  useEffect(() => {
    setDesignId(matchGalleryParams(location.pathname).designId ?? '')
  }, [location])

  return designId
}
